import React from "react"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { StaticImage } from "gatsby-plugin-image"

const styles = makeStyles(theme => {
  return {
    container: {
      padding: theme.spacing(10)
    }
  }
})

const fourOFour = () => {
  const classes = styles()
  return (
    <Layout>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Typography variant="h3" component="p" align="center">
              What are you looking for?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StaticImage
              src='../images/404.svg'
              placeholder="tracedSVG"
              loading='eager'
              alt="404"
              quality='100'
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default fourOFour